import React from 'react';
import useConfig from '../hooks/useConfig';

function FormatPrice({ value, fractionDigits = 2 }) {
  const { currentCurrency } = useConfig();
  const priceValue = parseFloat(value || 0).toFixed(fractionDigits);

  return (
    <span>
      {currentCurrency?.symbol} {priceValue}
    </span>
  );
}

export default FormatPrice;
