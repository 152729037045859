import React from 'react';

import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 5,
    margin: 0,
    maxWidth: '100%',
    width: '100%',
    border: 'none',
    borderRadius: 0,
    display: 'flex',
    background: 'none',
  },
};

const ProductThumbnailZoom = ({ open, onClose, url }) => {
  return (
    <Modal isOpen={open} onRequestClose={onClose} style={customStyles}>
      <img alt="" src={url} style={{ width: '100%' }} />
    </Modal>
  );
};

export default ProductThumbnailZoom;
