import React from 'react';
import usePrice from '../../hooks/usePrice';
import FormatPrice from './../FormatPrice';

function ProductPrice({ productId, selectedItemId }) {
  const { minPrice, maxPrice, getItemPrice, showRangePrice, customPrice } = usePrice(productId);

  const RenderProductPrice = () => {
    if (customPrice) {
      return <span className="custom-price">{customPrice}</span>;
    } else if (showRangePrice && minPrice < maxPrice) {
      return (
        <div className="product-range-price">
          <FormatPrice value={minPrice} /> - <FormatPrice value={maxPrice} />
        </div>
      );
    } else {
      return <FormatPrice value={minPrice} />;
    }
  };

  const RenderItemPrice = () => {
    return <FormatPrice value={getItemPrice(selectedItemId)} />;
  };

  return (
    <div className="product-price">
      {selectedItemId && <RenderItemPrice />}
      {!selectedItemId && <RenderProductPrice />}
    </div>
  );
}

export default ProductPrice;
