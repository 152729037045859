import { useSelector } from 'react-redux';
import UtilityService from '../services/utility';
import useLocalization from './useLocalization';

function usePrice(productId) {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const qrocde = useSelector((state) => state.qrcode);

  const product = menu?.products?.find((p) => parseInt(p.id) === parseInt(productId));

  const { currentCurrency } = useLocalization();

  const dineInExtraCostEnabled = qrocde?.settings?.dineInExtraCostEnabled === 'true';
  const showRangePrice = qrocde?.settings?.SHOW_RANGE_PRICE === 'true';

  let extraCost = getExtraCost(product, dineInExtraCostEnabled, currentCurrency.id);
  let minPrice = 0;
  let maxPrice = 0;
  let price = 0;
  let itemPrice = 0;

  if (product && product.type === 'SIMPLE_PRODUCT') {
    price = parseFloat(product['price_' + currentCurrency.id] || 0);
    price = price + extraCost;
    minPrice = price;
    maxPrice = price;
  } else if (product && product.type === 'COMPLEX_PRODUCT') {
    if (product.items) {
      product.items.forEach((item) => {
        if (dineInExtraCostEnabled && !UtilityService.isAllowToDineIn(item)) {
          return;
        }
        itemPrice = item['price_' + currentCurrency.id];
        extraCost = getExtraCost(item, dineInExtraCostEnabled, currentCurrency.id);

        price = parseFloat(itemPrice || 0) + extraCost;

        if (minPrice === 0 || price < minPrice) {
          minPrice = price;
        }
        if (maxPrice === 0 || price > maxPrice) {
          maxPrice = price;
        }
      });
    }
  }

  const getItemPrice = (itemId) => {
    if (product.type === 'COMPLEX_PRODUCT' && product.items) {
      let itemPrice1 = 0;
      product.items.forEach((item) => {
        if (item.id == itemId) {
          itemPrice1 = item['price_' + currentCurrency.id];
          const extraCost = getExtraCost(item, dineInExtraCostEnabled, currentCurrency.id);
          itemPrice1 = parseFloat(itemPrice1 || 0) + extraCost;
        }
      });
      return itemPrice1;
    }
    return price;
  };

  return {
    price,
    itemPrice,
    minPrice,
    maxPrice,
    extraCost,
    customPrice: product['customPrice'],
    getItemPrice: getItemPrice,
    showRangePrice,
  };
}

export default usePrice;

const getExtraCost = (product, dineInExtraCostEnabled, currencyId) => {
  if (product?.type === 'SIMPLE_PRODUCT' && dineInExtraCostEnabled) {
    if (product.attribute) {
      for (let index = 0; index < product.attribute.length; index++) {
        const attr = product.attribute[index];
        if (attr.identifier === 'DINE_IN_EXTRA_COST_' + currencyId) {
          if (attr.value) {
            return parseFloat(attr.value.value || 0);
          }
        }
      }
    }
  }
  return 0;
};
