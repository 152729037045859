import React from 'react';
import CustomHelmet from '../components/common/CustomHelmet';
import Loading from '../components/common/Loading';
import useConfig from '../hooks/useConfig';
import useMenu from '../hooks/useMenu';

function CommonLayout(props) {
  const { id, faviconURL, name, description } = useMenu();
  const { currentLanguage } = useConfig();

  if (id) {
    require(`../styles/menu_${id}.css`); // Dynamically load the correct stylesheet
  }

  return (
    <section className={`layout common-layout menu-${id}`}>
      <CustomHelmet
        favicon={faviconURL}
        name={name}
        description={description}
        lang={currentLanguage?.code}
        direction={currentLanguage?.dir}
      />

      {/* Render Template only if menu is loaded */}
      {id && props.children}

      {id == null && <Loading />}
    </section>
  );
}

export default CommonLayout;
