import React, { useEffect, useState } from 'react';
import useMenu from '../../hooks/useMenu';

function GroupNavigation({ onChange }) {
  const { selectedCategory } = useMenu();

  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const groupList = selectedCategory?.groups.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));

  useEffect(() => {
    if (onChange) {
      onChange(selectedGroupId);
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (groupList && groupList.length > 0) {
      setSelectedGroupId(groupList[0].id);
    }
  }, [selectedCategory]);

  // don't render navigation if only one group
  if (groupList && groupList.length < 2) {
    return null;
  }

  return (
    <div className="group-navigation">
      <div className="group-list">
        {selectedCategory &&
          selectedCategory.groups
            ?.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
            .map((group) => {
              const active = selectedGroupId === group.id;
              return (
                <div
                  className={'group ' + (active ? 'active' : '')}
                  group-id={group.id}
                  key={group.id}
                  onClick={() => setSelectedGroupId(group.id)}
                >
                  {group.name}
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default GroupNavigation;
