import React from 'react';
import useMenu from '../../hooks/useMenu';
import GroupDetails from '../GroupDetails';
import useDesign from '../../hooks/useDesign';

function CategoryDetails({ topWidget, showAllCategory = false, selectedGroupId }) {
  const { selectedCategory, activeCategories } = useMenu();
  const { showGroupNavigation } = useDesign();

  const catList = showAllCategory ? activeCategories : [selectedCategory];

  return (
    <>
      {catList.map((cat) => {
        let greoupList = cat.groups ? cat.groups.sort((a, b) => a.sequence - b.sequence) : [];

        // If group navigation is enabled, then display only selected group
        if (showGroupNavigation) {
          greoupList = greoupList.filter((g) => parseInt(g.id) === parseInt(selectedGroupId));
        }

        return (
          <div className="category-details" category-id={cat.id} key={cat.id}>
            {topWidget}

            {greoupList.map((group) => {
              return <GroupDetails key={group.id} group={group} />;
            })}
          </div>
        );
      })}
    </>
  );
}

export default CategoryDetails;
