import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';
import useDesign from '../../hooks/useDesign';
import Icon from './Icon';
import IconText from './IconText';
import Text from './Text';
import ArrowNavigation from './ArrowNavigation';

function CategoryNavigation({ showAllCategory = false }) {
  const { uuid } = useQRCode();
  const { categoryNavigationType, displaySelectedCategoryNameEnabled, categoryNavigationArrowEnabled } = useDesign();
  const { activeCategories, selectedCategory } = useMenu();
  const scrollContainerRef = useRef(null);
  const scrollAmount = 200;

  const navigate = useNavigate();

  const handleCategorySelect = (categoryId) => {
    navigate(`/m/${uuid}/category/${categoryId}`);

    if (showAllCategory) {
      const targetDiv = document.querySelector(`[category-id="${categoryId}"]`);

      if (targetDiv) {
        // Scroll into view
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  let className = 'category-navigation category-navigation-' + categoryNavigationType.toLowerCase();
  if (categoryNavigationArrowEnabled) {
    className += ' arrow-enabled';
  }

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={className}>
      {categoryNavigationArrowEnabled && <ArrowNavigation direction="right" onClick={() => handleScroll('right')} />}

      <div className="category-list" ref={scrollContainerRef} style={{ scrollBehavior: 'smooth' }}>
        {activeCategories &&
          activeCategories.map((category) => {
            const active = selectedCategory && selectedCategory.id == category.id;
            if (categoryNavigationType === 'ICONTEXT') {
              return (
                <IconText
                  key={category.id}
                  categoryId={category.id}
                  name={category.name}
                  src={category.icon}
                  active={active}
                  onClick={() => handleCategorySelect(category.id)}
                />
              );
            } else if (categoryNavigationType === 'ICON') {
              return (
                <Icon
                  key={category.id}
                  categoryId={category.id}
                  src={category.thumbnail}
                  active={active}
                  onClick={() => handleCategorySelect(category.id)}
                />
              );
            } else {
              return (
                <Text
                  key={category.id}
                  categoryId={category.id}
                  name={category.name}
                  active={active}
                  onClick={() => handleCategorySelect(category.id)}
                />
              );
            }
          })}
      </div>

      {categoryNavigationArrowEnabled && <ArrowNavigation direction="left" onClick={() => handleScroll('left')} />}

      {displaySelectedCategoryNameEnabled && selectedCategory && (
        <div className="selected-category-name">{selectedCategory.name}</div>
      )}
    </div>
  );
}

export default CategoryNavigation;
