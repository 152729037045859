import React from 'react';

function ArrowNavigation({ direction, onClick }) {
  const getPath = () => {
    if (direction === 'right') {
      return (
        <path d="M1600 4547 c-91 -26 -173 -97 -213 -186 -32 -69 -29 -179 5 -251 14 -30 39 -69 54 -86 16 -18 426 -352 912 -744 485 -391 882 -715 882 -720 0 -5 -397 -329 -882 -720 -486 -392 -896 -726 -912 -744 -53 -58 -80 -132 -80 -216 0 -126 67 -231 182 -287 75 -37 182 -39 258 -5 33 15 428 328 1124 890 1053 851 1074 868 1109 932 33 60 36 71 36 150 0 79 -3 90 -36 150 -35 64 -56 81 -1109 932 -724 585 -1090 874 -1124 889 -62 26 -146 33 -206 16z" />
      );
    } else if (direction === 'left') {
      return (
        <path d="M3353 4546 c-24 -8 -57 -23 -76 -33 -57 -34 -2161 -1740 -2176 -1764 -47 -77 -55 -104 -55 -189 -1 -78 2 -90 34 -149 34 -62 54 -80 1110 -932 591 -478 1095 -879 1120 -891 36 -18 62 -23 130 -23 79 0 90 3 150 36 160 88 215 273 127 433 -32 58 -71 91 -932 787 -495 399 -901 731 -902 736 -2 6 393 331 879 723 486 391 896 726 912 743 84 93 106 240 53 348 -32 66 -100 132 -162 160 -58 26 -158 33 -212 15z" />
      );
    }
  };
  return (
    <div className={'arrow-navigation ' + direction} onClick={onClick}>
      <svg width="32" height="32" viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
          {getPath()}
        </g>
      </svg>
    </div>
  );
}

export default ArrowNavigation;
