import React, { useEffect, useState } from 'react';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useQRCode from '../../hooks/useQRCode';
import useDesign from '../../hooks/useDesign';
import GroupNavigation from '../../components/GroupNavigation';

function SinglePageLayoutTemplate() {
  const { uuid } = useQRCode();

  const { showAllCategory, showGroupNavigation } = useDesign();
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  return (
    <div className="single-page-layout main-card">
      <Header showBackButton={false} showShareButton={true} showBusinessDetails={true} backURL={'/m/' + uuid} />

      <div className="body">
        <CategoryNavigation showAllCategory={showAllCategory} />

        {showGroupNavigation && <GroupNavigation onChange={setSelectedGroupId} />}

        <CategoryDetails showAllCategory={showAllCategory} selectedGroupId={selectedGroupId} />
      </div>
      <Footer />
    </div>
  );
}

export default SinglePageLayoutTemplate;
